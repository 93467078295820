import React, { Fragment } from "react"
import { Icon } from "react-icons-kit"
import { chevronDown } from "react-icons-kit/feather/chevronDown"
import { chevronLeft } from "react-icons-kit/feather/chevronLeft"
import styled from "styled-components"
import Slider from "react-slick"

import Button, { IconButton } from "../Button"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Div = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-item: center;
  flex-wrap: wrap;
  .left-scroll-down {
    width: 10%;
  }
  .info {
    width: 40%;
    min-width: 320px;
    padding: 30px 10px;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .left-scroll-down {
    width: 5%;
    position: relative;
    span {
      padding: 10px;
      position: absolute;
      bottom: 10%;
      transform: rotate(-90deg);
    }
  }
  .carousel {
    width: 55%;
    min-width: 320px;
    max-height: 800px;
    overflow: hidden;
    img {
      width: 100%;
    }
    p {
      position: absolute;
      bottom: 0px;
      background: white;
      width: 100%;
      padding: 10px;
    }
  }
  @media screen and (max-width: 948px) {
    .left-scroll-down {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .info {
      margin-top: 50px;
    }
    .carousel,
    .info {
      width: 100%;
    }
  }
  .update-bar {
    padding: 8px 15px 8px 3px;
    border-radius: 50px;
    background: #eee;
    font-size: 0.8em;
    line-height: 50px;
    font-family: Oswald;
    span {
      font-family: Oswald;
      background: orange;
      padding: 5px 10px;
      margin-right: 5px;
      border-radius: 30px;
    }
  }
  h1 {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 3.5em;
    font-family: oswald;
  }
  h3 {
    font-weight: bold;
    font-size: 1.3em;
    margin: 10px 0px;
  }
  .carousel-navigation {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .rotate-180 {
    display: inline-block;
    transform: rotate(180deg);
  }
  .content {
    line-height: 30px;
  }
`

const BannerSection = props => {
  const slider = React.createRef()
  const nextSlide = () => slider.current.slickNext()
  const prevSlide = () => slider.current.slickPrev()
  return (
    <Div>
      <div className="left-scroll-down">
        <span>
          <AnchorLink className="smooth_scroll" href="#milestone" offset={81}>
            <b>Scroll Down</b>
            <Icon icon={chevronLeft} />
          </AnchorLink>
        </span>
      </div>
      <div className="info">
        <div>
          <span className="update-bar">
            <span>UPDATE</span>
            Chairman's Note For Concepts for Sustainable forest management{" "}
            <a href="/">Read document</a>
          </span>
          <h1>Rokka Groups</h1>
          <h3>
            We deliver best of the furnished goods from nature with respect to
            it.
          </h3>
          <p className="content">
            “ROKKA GROUP” consists of different industries involved in timber
            business, wood seasoning, forest products, furniture, herbal
            products, Organic Product, Kattha, Rosin & Turpentine, Housing &
            Land Development, Government supply, Project Base works etc. Mr.
            Basanta Rokka Chhetri, the chairperson, had started the venture for
            more than 45 years and as a result there are about 15 “Fifteen”
            companies & industries under the same umbrella, ROKKA GROUP.
          </p>

          <AnchorLink className="smooth_scroll" href="#about" offset={81}>
            <Button>Read More </Button>
          </AnchorLink>
        </div>
        <div className="carousel-navigation">
          <IconButton onClick={prevSlide}>
            <Icon icon={chevronLeft} size={30} />
          </IconButton>
          <div className="rotate-180">
            <IconButton onClick={nextSlide}>
              <Icon icon={chevronLeft} size={30} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="carousel">
        <Slider ref={slider}>
          <div>
            <img src={require("../../images/cover/1.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/2.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/3.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/4.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/5.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/6.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/7.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/8.jpg")} />
            <p></p>
          </div>
          <div>
            <img src={require("../../images/cover/9.jpg")} />
            <p></p>
          </div>
        </Slider>
      </div>
    </Div>
  )
}

export default BannerSection
