import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import BranchSection from "../components/BranchSection"
import MilestoneBlock from "../components/MilestoneBlock"
import Header from "../components/header"
import "../style/index.css"
import BlogSection from "../components/BlogSection"
import Footer from "../components/Footer"
import styled from "styled-components"
import SEO from "../components/seo"

import footerBg from "../images/footer-bg.png"
import AboutPage from "./about-us"

const Div = styled.div`
  background-image: url(${footerBg});
  background-repeat: no-repeat;
`
const Div2 = styled.div`
  background-color: #fafbfe;
`
const Note = styled.div`
  width: 100%;
  background: #90ee90;
  padding: 80px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  * {
    font-family: Fjalla;
  }
  h1 {
    font-size: 1.3em;
    margin: 10px 0px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />
    <MilestoneBlock />
    <AboutPage />
    <BranchSection />
    <Div>
      <BlogSection />
    </Div>
    <Note>
      <h1>CHAIRMAN’S NOTE</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam erat
        augue, malesuada vel lorem eget.
      </p>
      <h4>- Deepak Rokka</h4>
    </Note>
  </Layout>
)

export default IndexPage
