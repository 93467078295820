import React from "react"
import Text from "../../reusecore/elements/Text"
import Heading from "../../reusecore/elements/Heading"
import Container from "../../common/components/UI/Container"
import BlockWrapper, {
  MilestoneCard,
  CounterWrapper,
  CounterItem,
} from "./milestoneBlock.style"
import Button from "../Button"

const MilestoneBlock = () => {
  const data = {
    title: "WE INSPIRE LOCAL COMMUNITIES",
    amount: "30+",
    text: "Districts/Rural Areas are working with us",
    counterItems: [
      {
        title: "Cooperating and currently working with",
        subTitle: "local communities from different districts/rural areas",
        amount: "300+",
        id: 1,
      },
      {
        title: "Directly managing and working with more than",
        subTitle: "local people from different areas",
        amount: "1500+",
        id: 2,
      },
      {
        title:
          "Operating and maintaing good business relations with local level as well as internationally",
        subTitle: "",
        amount: "",
        id: 3,
      },
      {
        title:
          "Currently Running/in operation herbal business, Rosin & Turpentine, Kattha(Acacia, Catechu) and other industrial chemical and consumer goods.",
        subTitle: "",
        amount: "",
        id: 4,
      },
    ],
  }

  const { title, amount, text, counterItems } = data

  return (
    <Container id="milestone" width="1260px">
      <BlockWrapper>
        <MilestoneCard>
          <Text content={title} /> <Heading content={amount} />
          <Text mb="0rem" content={text} />
        </MilestoneCard>
      </BlockWrapper>
      <CounterWrapper>
        {counterItems &&
          counterItems.map(item => (
            <CounterItem key={`counter_key${item.id}`}>
              <Text content={item.title} />
              <Heading as="h3" content={item.amount} />
              <Text content={item.subTitle} />
            </CounterItem>
          ))}
      </CounterWrapper>
    </Container>
  )
}

export default MilestoneBlock
