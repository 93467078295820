import React from "react"
import Container from "../../common/components/UI/Container"
import Heading from "../../reusecore/elements/Heading"
import BlogPost from "../../common/components/BlogPost"
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  LinkArea,
  Text,
  PostArea,
} from "./blogSection.style"
import Button from "../Button"

const BlogSection = () => {
  const data = [
    {
      id: 1,
      thumbUrl:
        "https://www.onlinekhabar.com/wp-content/uploads/2019/02/mount-kattha.jpg",
      title:
        "सरकारलाई उद्योगीको सुझाव- भारतसँग व्यापार घाटा कम गर्न सल्लो र खयर रोपौं",
      excerpt:
        "भारतसँग नेपालको व्यापार घाटा कहालीलाग्दो अवस्थामा छ । आयातको तुलनामा निर्यात नगण्य छ । यस्तो निराशाजनक अवस्थाका बीच नेपालमा केही यस्ता उद्योगहरु पनि छन्, जसले आफ्नो उत्पादनको करिब ९५ प्रतिशत हिस्सा भारत निर्यात गरेर वर्षेनी करोडौं रुपैयाँ नेपाल भित्र्याइरहेका छन् ।",
      btnText: "Continue Reading",
    },
    {
      id: 1,
      thumbUrl:
        "https://miro.medium.com/max/1400/1*8cpPk7jaMK92uDcqDJ_PKg.jpeg",
      title:
        "Gajurmukhi Herbal was built on a mission to provide Organic Essential oil to the world. ",
      excerpt:
        "Initially started with few small vessels, Gajurmukhi now owns state of the art processing facility consisting of all Stainless Vessels and pipe networks. As Essential oil are Precious substances, proper instruments and Skills are required to obtain Pure and organic Oils.",
      btnText: "Continue Reading",
    },
  ]

  return (
    <SectionWrapper id="blog">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content="Blog & News Updates" />
          </TitleArea>
        </SectionHeader>
        <PostArea>
          {data &&
            data.map(item => (
              <BlogPost
                key={`blog__post-key${item.id}`}
                thumbUrl={item.thumbUrl}
                title={item.title}
                excerpt={item.excerpt}
                link={<Button>{item.btnText}</Button>}
              />
            ))}
        </PostArea>
      </Container>
    </SectionWrapper>
  )
}

export default BlogSection
