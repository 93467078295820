import styled from 'styled-components';

const TabWrapper = styled.div ``;

export const TabMenu = styled.ul ``;

export const MenuItem = styled.li `
  cursor: pointer;

  &.active {
    font-weight: 700;
  }
`;

export const TabContent = styled.div ``;

export const TabPanel = styled.div ``;

export default TabWrapper;