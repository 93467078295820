import React from "react"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import Text from "../../reusecore/elements/Text"
import Heading from "../../reusecore/elements/Heading"
import Tab, { Panel } from "../../common/components/Tabs"
import SectionWrapper, { ContentWrapper } from "./branchSection.style"
import Button from "../Button"

const BranchSection = () => {
  const data = [
    {
      menuItem: "Gajurmukhi Herbal Pvt. Ltd",
      id: "1",
      slogan: "Essence of nature from top of the world.",
      logo: '/company/logo/gajurmukhi.png',
      title:
        "Farming in <strong>500+</strong> hectare of private and community forest",
      linkText: "Detail",
      description:
        "GHPL centres to give Natural, organic, Health- Enhancing, Undefiled Essential oil to our clients.GHPL safeguards the sustainability of the oils...",
      image: "/company/gajurmukhi.jpg",
      linkUrl: "/gajurmukhi-herbal",
    },
    {
      menuItem: "Mount Rosin & Turpentine Industries Pvt. Ltd.",
      id: "2",
      slogan: "",
      logo: "/company/logo/mount-rosin-and-turpentine.png",
      title:
        "Collection/Processing of Khoto to Make <strong>Rosin & Turpentine</strong>.",
      linkText: "Detail",
      description:
        "Exports of Rosin & Turpentine in India as well as third countries.",
      image: "/company/rosin.jpg",
      linkUrl: "/mount-rosin",
    },
    {
      menuItem: "Kasthamandal Wood And Seasoning Industries Pvt. Ltd.",
      id: "3",
      slogan: "",
      logo: "/company/logo/kasthamandal.png",
      title: " All kinds of wooden business wood treatment",
      linkText: "Detail",
      description: `
      Purchase of wood from the district forest office, community forest office, personal forest and import raw wooden logs, raw woods and size lumber from different countries, like Vietnam, Indonesia, Malaysia, Myanmar, Laos, Cambodia, South Africa, Canada And many more.`,
      image: "/company/kasthamandal.jpg",
      linkUrl: "/kasthamandal",
    },
    {
      menuItem: "Mount Everest Kattha Mills Pvt. Ltd.",
      id: "3",
      slogan: "",
      logo: "/company/logo/mt-everest-kattha.png",
      title: "All kinds of wooden business wood treatment",
      linkText: "Detail",
      description: `
      Purchase of wood from the district forest office, community forest office, personal forest and import raw wooden logs, raw woods and size lumber from different countries, like Vietnam, Indonesia, Malaysia, Myanmar, Laos, Cambodia, South Africa, Canada And many more.  `,
      image: "/company/everest.jpg",
      linkUrl: "/mount-kattha",
    },
  ]

  const title = text => {
    return {
      __html: text,
    }
  }

  return (
    <SectionWrapper id="branch">
      <Tab active={0}>
        {data.map(item => (
          <Panel
            title={<Text content={item.menuItem} />}
            key={`tab_key${item.id}`}
          >
            <ContentWrapper>
              <Fade>
                <div className="image">
                  {item.image && <img src={item.image} className="tab_image" />}
                </div>
              </Fade>
              <div className="content">
                {
                  item.logo && <img src={item.logo} alt={item.title} width={100}/>
                }
                <Heading as="h4" content={item.slogan} />
                <h2 dangerouslySetInnerHTML={title(item.title)} />
                <Text content={item.description} />
                <a href={item.linkUrl}>
                  <Button>{item.linkText}</Button>
                </a>
              </div>
            </ContentWrapper>
          </Panel>
        ))}
      </Tab>
    </SectionWrapper>
  )
}

export default BranchSection
